import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { Container, Row, Col } from 'react-grid-system';
import ReactMarkdown from 'react-markdown';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/careers-hero.jpg';
import Follow from '../components/rows/cta/follow';
import Cta from '../components/rows/cta/index';

export default function CardiologyCare({ data }) {
  const content = data.strapiCardiologyCare;

  return (
    <Layout>
      <Seo title="Cardiology Care" />
      <Hero title="Covid-19 Cardiology Care" banner={Banner} />
      <Container>
        <Row key={content.id} className="cardio-care">
          <Col md={8} sm={12} className="mx-auto">
            <h2 className="text-center">{content.ccare_title}</h2>
            <ReactMarkdown children={content.ccare_description} />
          </Col>
        </Row>
      </Container>
      <Cta />
      <div className="mt-56">
        <Follow />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query CardiologyCare {
    strapiCardiologyCare {
      id
      ccare_title
      ccare_description
    }
  }
`;
