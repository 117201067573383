import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'gatsby';
// import ReactMarkdown from 'react-markdown'
// import Button from '../../elements/button'

export default function Hero(props) {
  return (
    <div
      className="banner-row shadow-lg flex items-center mb-24 md:mb-52"
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${props.banner})`,
      }}
    >
      <Container className="w-full">
        <Row>
          <Col md={9} sm={12} className="hero">
            <div className="white-bg">
              <h1>{props.title}</h1>
              <h5>{props.description}</h5>
              <span className="breadcrumb inline-flex items-center mt-20">
                <Link to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-home"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#000000"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="5 12 3 12 12 3 21 12 19 12" />
                    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                    <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                  </svg>
                </Link>
                {props.service ? (
                  <Link to="/services">
                    <span className="mx-5">/</span> Our Services
                  </Link>
                ) : null}
                {props.ct ? (
                  <Link to="/conditions-treatments">
                    <span className="mx-5">/</span> Conditions/Treatments
                  </Link>
                ) : null}
                {props.team ? (
                  <Link to="/specialists">
                    <span className="mx-5">/</span> Our specialist team
                  </Link>
                ) : null}
                {props.team ? (
                  <>
                    <span className="divider mx-5">/</span>
                    <span className="text-red-300 bold red">
                      {props.member}
                    </span>
                  </>
                ) : (
                  <>
                    <div className={props.ct ? 'active-bread' : ''}>
                      <span className="divider mx-5">/</span>
                      <span className="text-red-300 bold red">
                        {props.title}
                      </span>
                    </div>
                  </>
                )}
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
